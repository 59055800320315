"use client";

import useIsDesktop from "../../../../hooks/useIsDesktop";
import { sendEventGA } from "../../../../lib/google/google-analytics";
import { Icons } from "../../../icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { UserAvatar } from "../../../user-avatar";
import { LOCAL_STORAGE_KEY, SESSION_RESET_STORAGE_KEY } from "../../states/messaging/use-messaging-store";
import { signOut } from "next-auth/react";
import React, { useState } from "react";

function UserMenu({ user, handleOpenLogin }) {
  const [isOpen, setIsOpen] = useState(false);
  const isDeskTop = useIsDesktop();

  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      {user ? (
        <>
          <DropdownMenuTrigger
            onClick={() => {
              sendEventGA("sidebar_account_click");
            }}
          >
            <div
              className={`flex items-center justify-between rounded-full p-2 text-sm font-medium text-tertiary-50 ${
                isOpen ? "bg-white " : ""
              }`}
            >
              <div className="flex w-[142px] max-w-[142px] items-center gap-2 ">
                <UserAvatar
                  user={{
                    name: user.name || null,
                    image: user.image || null,
                  }}
                  iconFallbackSize={6}
                  className="size-6 rounded-full !object-cover"
                />
                <div className="truncate text-sm font-semibold text-secondary-100">
                  {user.name || user.email}
                </div>
              </div>
              {isOpen ? (
                <Icons.chevronDown stroke="#404867" className="shrink-0" />
              ) : (
                <Icons.chevronUp stroke="#404867" className="shrink-0" />
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[80vw] rounded-2xl bg-white !p-0 shadow-lg md:w-auto"
            style={{
              transform: isDeskTop
                ? "translate(16px, -8px)"
                : "translate(0, -8px)",
              boxShadow: isDeskTop ? "0px 4px 20px 0px rgba(0, 0, 0, 0.1)" : "",
            }}
          >
            <div className="flex gap-2 p-3">
              <UserAvatar
                user={{
                  name: user.name || null,
                  image: user.image || null,
                }}
                iconFallbackSize={10}
                className="size-10 rounded-full !object-cover"
              />
              <div className="flex flex-col gap-0.5">
                <div className="text-sm font-semibold text-secondary-100 ">
                  {user.name}
                </div>
                <div className="text-sm font-normal text-secondary-100 ">
                  {user.email}
                </div>
              </div>
            </div>
            <DropdownMenuItem
              className="flex gap-2 !px-4 !py-3 text-sm font-medium"
              onSelect={() => {
                if (window.history.state) {
                  window.history.replaceState(null, "", "/");
                }
                signOut({
                  callbackUrl: `${window.location.origin}/chat`,
                })
                  .then(() => {
                    localStorage.removeItem(LOCAL_STORAGE_KEY);
                    sessionStorage?.removeItem(SESSION_RESET_STORAGE_KEY)
                  })
                  .catch((error) => {
                    console.error("Logout failed:", error);
                  });
              }}
            >
              <Icons.logOut className="size-5" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </>
      ) : (
        <button
          onClick={() => {
            sendEventGA("sidebar_login_click");
            handleOpenLogin();
          }}
          className="rounded-full border border-tertiary-50 bg-white py-3 text-sm font-medium text-tertiary-50"
        >
          Login
        </button>
      )}
    </DropdownMenu>
  );
}

export default UserMenu;
