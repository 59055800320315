"use client";

import { sendEventGA } from "../../../../lib/google/google-analytics";
import { Icons } from "../../../icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import useMenuSideBarStore from "../../hooks/menu/use-menu-sidebar-store";
import useValidateUserClick from "../../hooks/use-validate-user-click";
import { IconsChat } from "../../icon/icons-chat";
import useMessaging from "../../states/messaging/use-messaging-store";
import ChatHistorySection from "./chat-history-section";
import UserMenu from "./user-menu";
import { Logo } from "@/components/logo/logo";
import useIsDesktop from "@/hooks/useIsDesktop";
import * as Accordion from "@radix-ui/react-accordion";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

function SideBarContent({ chatHistory, user, handleOpenLogin }) {
  const { handleClick } = useValidateUserClick();
  const { messageData } = useMessaging();
  const pathName = usePathname();
  const router = useRouter();
  const { onCloseSideBar } = useMenuSideBarStore();
  const isDeskTop = useIsDesktop();

  const getChatType = () => {
    let storedTypeChat = "";
    if (typeof window !== "undefined") {
      storedTypeChat = sessionStorage?.getItem("type-chat") ?? "";
    }
    const isHomePage = storedTypeChat === "/home-page";
    return isHomePage ? "/home-page" : "/chat";
  };

  const totalMessageUnread = messageData.reduce((total, current) => {
    return total + (current?.badge ?? 0);
  }, 0);

  const formattedBadge =
    totalMessageUnread > 99
      ? "99+"
      : totalMessageUnread > 0
      ? totalMessageUnread.toString()
      : undefined;

  const buttons = [
    {
      path: getChatType(),
      label: getChatType() === "/chat" ? "New chat" : "Home",
      icon: <Icons.edit stroke="#111B42" className="size-5" />,
      event: "sidebar_new_chat_click",
      onClick: () => {
        router.push(getChatType());
        if (!isDeskTop) {
          onCloseSideBar();
        }
      },
      className: "rounded-full border border-secondary-40 bg-white p-3",
    },
    {
      path: "/explore",
      label: "Explore",
      icon: <IconsChat.SidebarExplore className="size-5" />,
      event: "",
      onClick: () => {
        router.push("/explore");
        if (!isDeskTop) {
          onCloseSideBar();
        }
      },
    },
    {
      path: "/saved",
      label: "Saved places",
      icon: <IconsChat.Heart />,
      event: "sidebar_saved_places_click",
      onClick: () => handleClick("/saved"),
    },
    {
      path: "/local-chat",
      label: "Expert chat",
      icon: (
        <IconsChat.ChatListFlag
          className="size-5"
          stroke="#0F183B"
          pathProps={{ fill: "#0F183B" }}
        />
      ),
      badge: formattedBadge,
      event: "",
      onClick: () => handleClick("/local-chat"),
    },
  ];

  useEffect(() => {
    if (!pathName) return;
    let chatType = "";
    if (typeof window !== "undefined") {
      chatType = sessionStorage?.getItem("type-chat") ?? "";
    }
    console.log(chatType, pathName, "chatType");

    if (["/home-page", "/chat"].includes(pathName)) {
      sessionStorage.setItem("type-chat", pathName);
    } else if (!chatType) {
      sessionStorage.setItem("type-chat", "/home-page");
    }
  }, [pathName]);

  return (
    <>
      {isDeskTop && (
        <Link href={getChatType() || "/chat"}>
          <Logo width={[62, 29]} height={22} />
        </Link>
      )}
      <div className="flex flex-1 flex-col gap-2">
        {buttons.length > 0 &&
          buttons.map(
            ({ path, label, icon, event, badge, onClick, className }) => {
              const isActive = pathName === path || pathName?.includes(path);
              return (
                <button
                  key={path}
                  onClick={() => {
                    if (event) sendEventGA(event);
                    onClick();
                  }}
                  className={`flex items-center gap-2 px-3 py-2 ${
                    isActive ? "rounded-full bg-white" : ""
                  } ${className || ""}`}
                >
                  {icon}
                  <span className="text-start text-sm font-medium">
                    {label}
                  </span>
                  {badge && (
                    <div className="h-full w-auto items-start">
                      <div className="w-fit min-w-[18px] max-w-8 rounded-full bg-[#F44622] px-1 text-center text-xs font-medium leading-[150%] text-white">
                        {badge}
                      </div>
                    </div>
                  )}
                </button>
              );
            }
          )}
        <div className="h-px bg-secondary-40" />
        <Accordion.Root
          className="h-full min-h-[250px]"
          type="single"
          defaultValue="1"
          collapsible
        >
          <AccordionItem
            value="1"
            className="h-full !border-none"
            onClick={() => sendEventGA("sidebar_recent_chat_click")}
          >
            <AccordionTrigger className="!px-3 !py-2">
              <button className="flex justify-between ">
                <div className="flex gap-2">
                  <IconsChat.Recent className="size-5" />
                  <span className="text-sm font-medium">Recent chat</span>
                </div>
              </button>
            </AccordionTrigger>
            <AccordionContent
              className="relative size-full max-h-[calc(100%-40px)] overflow-y-auto"
              classNameContent="!pb-0 absolute size-full"
            >
              <div className="size-full">
                <div className="flex size-full flex-col gap-4 p-1 ">
                  {user ? (
                    Object.keys(chatHistory).map((key) => (
                      <ChatHistorySection
                        key={key}
                        title={key}
                        data={chatHistory[key]}
                      />
                    ))
                  ) : (
                    <ChatHistorySection
                      key="today"
                      title="today"
                      data={chatHistory.today}
                    />
                  )}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion.Root>
      </div>
      <div className="flex cursor-pointer flex-col gap-4">
        <UserMenu user={user} handleOpenLogin={handleOpenLogin} />
        <div
          onClick={() =>
            window.open("https://forms.gle/SD9sYr12VJDLmN7w8", "_blank")
          }
          style={{ boxShadow: "0px 4px 20px 0px rgba(71, 113, 240, 0.18)" }}
          className="flex h-10 w-full min-w-[184px] items-center gap-2 rounded-full border border-white bg-white"
        >
          <div className="relative ml-1 flex size-8 items-center justify-center rounded-full bg-blue-1">
            <Icons.twitchMessage stroke="#FFF" className=" size-6 shrink-0" />
          </div>
          <span className="text-sm font-medium text-blue-1">
            Feedback for Laka
          </span>
        </div>
      </div>
    </>
  );
}

export default SideBarContent;
