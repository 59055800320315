import { trackAmplitudeEvent } from "@/components/amplitude-provider";
import useAppStore from "@/components/provider/app-store";

declare global {
  interface Window {
    gtag(type: "config", googleAnalyticsId: string, options: any);
    gtag(type: "event", eventAction: string, fieldObject: any);
  }
}
const customClickEvents = [
  "aid_detail_select_combo_click",
  "aid_detail_select_buy_click",
  "aid_detail_add_cart_click",
  "aid_detail_combo_click",
  "aid_detail_aid_click",
  "aid_detail_buynow_click",
  "checkout_view",
  "cart_view",
  "ecom_iti_banner_click",
  "ecom_cate_click",
  "ecom_filter_click",
  "ecom_aid_click",
  "ecom_aid_viewmore_click",
  "act_select_continue_click",
  "iti_list_act_detail_click",
  "iti_list_add_act_click",
  "iti_list_transportation_click",
  "iti_list_direction_click",
  "iti_list_map_click",
  "iti_list_select_day_click",
  "iti_list_overview_aid_top_click",
  "iti_list_overview_viewday_click",
  "iti_list_overview_viewdetail_click",
  "iti_list_overview_tip_click",
  "iti_list_overview_aid_bot_click",
  "iti_list_overview_combo_click",
  "iti_list_overview_signin_click",
  "iti_list_day_aid_click",
  "iti_list_day_combo_click",
  "iti_list_edit_click",
  "iti_act_detail_add_act_click",
  "iti_act_detail_aid_click",
  "iti_act_detail_combo_click",
  "aid_detail_package_click",
  "package_detail_selectbuy_click",
  "package_detail_add_cart_click",
  "package_detail_buynow_click",
  "chat_mess_request",
  "chat_city_detail_click",
  "chat_act_detail_click",
];
export const sendEventGA = (
  action: string,
  value: { [key: string]: string | number | boolean } = {}
) => {
  if (typeof window !== "undefined" && window.gtag) {
    const user = useAppStore.getState().user;
    value.uid = user?.id || "";
    if (customClickEvents.includes(action)) value.custom = "return_trigger";
    console.log("event_GA:", action, value);
    trackAmplitudeEvent(action, value);
    window.gtag("event", action, value);
  }
};
