import { ChatConversationData } from "@/admin/actions/chat/type";
import { ChatMessage, ConversationRead, TravelBuddy } from "@prisma/client";
import { create } from "zustand";

export interface MessageData extends ChatConversationData {
  openedAt?: number;
  badge?: number;
  isActive?: boolean;
  isFixed?: boolean;
  buddy: TravelBuddy | null;
  messages: ChatMessage[];
  ConversationRead: ConversationRead[];
}

interface MessageStore {
  loading: boolean;
  messageData: MessageData[];
  setLoading: (loading: boolean) => void;
  setMessageData: (
    chatData: MessageData[] | ((prevData: MessageData[]) => MessageData[])
  ) => void;
  reset: () => void;
}

export const LOCAL_STORAGE_KEY = "message-store";
export const SESSION_RESET_STORAGE_KEY = "reset-message-store";

const getInitialStore = (): {
  loading: boolean;
  messageData: MessageData[];
} => {
  if (typeof window !== "undefined") {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedData
      ? JSON.parse(storedData)
      : { loading: false, messageData: [] };
  }
  return { loading: false, messageData: [] };
};

export const initStore = {
  loading: false,
  messageData: [],
};

const useMessaging = create<MessageStore>((set) => {
  const initialStore = getInitialStore();

  return {
    ...initialStore,

    setLoading: (loading) => set(() => ({ loading })),

    setMessageData: (newData) =>
      set((prevState) => {
        const updatedMessageData =
          typeof newData === "function"
            ? newData(prevState.messageData)
            : newData;

        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({
            loading: prevState.loading,
            messageData: updatedMessageData,
          })
        );

        return { messageData: updatedMessageData };
      }),

    reset: () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      set({ loading: false, messageData: [] });
    },
  };
});

export default useMessaging;
