import { User } from "@prisma/client";
import { AvatarProps } from "@radix-ui/react-avatar";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Icons } from "@/components/icons";

interface UserAvatarProps extends AvatarProps {
  user: Pick<User, "image" | "name">;
  iconFallbackSize?: number;
}

export function UserAvatar({
  user,
  iconFallbackSize = 14,
  ...props
}: UserAvatarProps) {
  return (
    <Avatar {...props}>
      {user.image ? (
        <AvatarImage alt="Picture" src={user.image} {...props} />
      ) : (
        <AvatarFallback>
          <span className="sr-only">{user.name}</span>
          <Icons.user className={`size-${iconFallbackSize}`} />
        </AvatarFallback>
      )}
    </Avatar>
  );
}
