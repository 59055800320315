"use client";

import { useRouter } from "next/navigation";
import useLoginModalStore from "../../../hooks/auth/use-login-modal-store";
import useIsDesktop from "../../../hooks/useIsDesktop";
import useAppStore from "../../provider/app-store";
import useMenuSideBarStore from "./menu/use-menu-sidebar-store";

function useValidateUserClick() {
  const { user } = useAppStore();
  const router = useRouter();
  const isDeskTop = useIsDesktop();
  const { onCloseSideBar } = useMenuSideBarStore();
  const loginModal = useLoginModalStore();

  const handleClick = (url = "/chat") => {
    if (user) {
      router.push(url);
      if (!isDeskTop) {
        onCloseSideBar();
      }
    } else {
      loginModal.onOpen(window.location.href);
    }
  };

  return { handleClick };
}

export default useValidateUserClick;
