"use client";

import { MessageData } from "../../states/messaging/use-messaging-store";
import { useParams, useRouter } from "next/navigation";
import { useEffect } from "react";

const useChatSocket = (
  socket: any,
  setMessageData: (
    chatData: MessageData[] | ((prevData: MessageData[]) => MessageData[])
  ) => void
) => {
  const params = useParams<{ chatId: string }>();
  const router = useRouter();

  useEffect(() => {
    if (!socket) return;

    const handleReceiveMessage = (res: any) => {
      setMessageData((prev: MessageData[]) => {
        const updatedChats = prev.map((chat: MessageData) =>
          chat.id === res.conversationId
            ? {
                ...chat,
                messages: chat.messages?.some(
                  (msg: any) => msg.id === res.uniqueId
                )
                  ? chat.messages.map((msg: any) =>
                      msg.id === res.uniqueId ? res : msg
                    )
                  : [...(chat.messages || []), res],
              }
            : chat
        );

        const updatedChat = updatedChats.find(
          (chat) => chat.id === res.conversationId
        );
        const otherChats = updatedChats.filter(
          (chat) => chat.id !== res.conversationId
        );

        return updatedChat ? [updatedChat, ...otherChats] : prev;
      });
    };

    const handleConversationRead = (res: any) => {
      setMessageData((prev: MessageData[]) =>
        prev.map((chat: MessageData) =>
          chat.id === res.conversationId
            ? {
                ...chat,
                isFixed: true,
                badge: chat?.badge ? chat?.badge + 1 : 1,
                ConversationRead: chat.ConversationRead.map((a) => ({
                  ...a,
                  countMessageUnread: a.countMessageUnread + 1,
                })),
              }
            : chat
        )
      );
    };

    const handleNewConversation = (res: any) => {
      let newId = "";
      console.log(newId, params?.chatId, "params?.chatId");
      setMessageData((prev: MessageData[]) =>
        prev.map((chat: MessageData) => {
          const isMatchingChat = res.chatMembers.every((member: any) =>
            chat.id.includes(member.userId)
          );

          if (isMatchingChat) {
            newId = res.id;
            return { ...chat, id: res.id, chatMembers: res.chatMembers };
          }
          return chat;
        })
      );

      console.log(newId, params?.chatId, "params?.chatId");

      if (newId && params?.chatId.includes("buddy")) {
        console.log(`push`);

        router.push(`/local-chat/${newId}`);
      }
    };

    socket.on("receiveMessage", handleReceiveMessage);
    socket.on("conversationRead", handleConversationRead);
    socket.on("newConversation", handleNewConversation);

    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
      socket.off("conversationRead", handleConversationRead);
      socket.off("newConversation", handleNewConversation);
    };
  }, [socket, setMessageData, params?.chatId]);
};

export default useChatSocket;
