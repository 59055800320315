import { TravelCity, TravelCityPhoto } from "@prisma/client";

export const defaultChatbotContext = {
  cities: [],
  interests: [],
  visa: false,
  sim: false,
  currency: false,
  transportation: false,
  buddy: false,
  onlyAskBuddy: false,
  place: false,
  itinerary: false,
  country: ["Vietnam"],
  departureCountry: [],
  departureCities: [],
  emergency: false,
  fee: false,
  botProfile: false,
  type: "NOTHING",
  questionCount: 0,
};
export type ConversationContext = {
  emergency: boolean;
  fee: boolean;
  botProfile: boolean;
  departureCountry: string[];
  departureCities: string[];
  cities: string[];
  interests: string[];
  visa: boolean;
  sim: boolean;
  itinerary: boolean;
  currency: boolean;
  transportation: boolean;
  buddy: boolean;
  place: boolean;
  country: string[];
  onlyAskBuddy?: boolean;
  questionType?: string;
  questionCount: number;
  question?: string;
  type: string;
};

export type PlaceData = {
  id: string;
  name: string;
  city: string;
  ggType: string;
  photos: string[];
};
export type CityData = {
  id: number;
  name: string;
  photos: string[];
};
export type paragraphParser = {
  text: string;
  places: PlaceData[];
  cities: CityData[];
};
export type ExtractAnswerData = {
  cities: string[];
  paragraphs: {
    text: string;
    places: {
      name: string;
      city: string;
      type: "CITY" | "PLACE";
    }[];
  }[];
};
export type TravelCityData = TravelCity & {
  photos: TravelCityPhoto[];
};
export type AnswerTransform = {
  paragraphs: paragraphParser[];
};

export type GetQuestionResponse = {
  question: string;
  type: string;
};
export type ChatResponse = {
  answer: string | null;
  type: string;
  question?: string;
  extractAnswer: AnswerTransform | null;
};
