import { TravelBuddy } from "@prisma/client";
import { create } from "zustand";

interface WaitlistLocalGuideProps {
  isOpen: boolean;
  joined: boolean;
  travelBuddy: TravelBuddy | null;
  onOpen: (travelBuddy?: TravelBuddy | null, joined?: boolean) => void;
  onClose: () => void;
}

const useOpenWaitlistLocalGuide = create<WaitlistLocalGuideProps>((set) => ({
  isOpen: false,
  joined: false,
  travelBuddy: null,
  onOpen: (travelBuddy, joined) =>
    set({ isOpen: true, travelBuddy: travelBuddy, joined }),
  onClose: () => set({ isOpen: false }),
}));

export default useOpenWaitlistLocalGuide;
