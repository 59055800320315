"use client";

import { IconsChat } from "../../icon/icons-chat";
import useMessaging from "../../states/messaging/use-messaging-store";
import { Skeleton } from "@/components/ui/skeleton";
import useLoginModalStore from "@/hooks/auth/use-login-modal-store";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";

const initItems = [
  {
    name: "Explore",
    icon: IconsChat.NavExplore,
    iconActive: IconsChat.NavActiveExplore,
    path: "/explore",
    requireLogin: false,
  },
  {
    name: "Saved",
    icon: IconsChat.NavSaved,
    iconActive: IconsChat.NavActiveSaved,
    path: "/saved",
    requireLogin: true,
  },
  {
    name: "Expert chat",
    icon: IconsChat.NavLocalChat,
    iconActive: IconsChat.NavActiveLocalChat,
    path: "/local-chat",
    requireLogin: true,
  },
  {
    name: "My profile",
    icon: IconsChat.NavMyProfile,
    iconActive: IconsChat.NavActiveMyProfile,
    path: "/personal",
    requireLogin: true,
  },
];

const ChatNavigationBar = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(initItems);
  const router = useRouter();
  const pathName = usePathname();
  const loginModal = useLoginModalStore();
  const { messageData } = useMessaging();

  const handleChangeNavigate = (path: string, requireLogin: boolean) => {
    if (requireLogin && !user) {
      loginModal.onOpen(`${window.location.href}`);
      return;
    }
    router.push(path);
  };

  const isActive = (href) => {
    if (pathName) {
      return new RegExp(`(^|/)${href}(/|$)`).test(pathName) ? "active" : "";
    }
    return false;
  };

  const totalMessageUnread = messageData.reduce((total, current) => {
    return total + (current?.badge ?? 0);
  }, 0);

  useEffect(() => {
    let storedTypeChat = "";
    if (typeof window !== "undefined") {
      storedTypeChat = sessionStorage?.getItem("type-chat") ?? "";
    }
    const isHomePage = storedTypeChat === "/home-page";
    const mainItem = {
      name: isHomePage ? "Home" : "Chat",
      icon: isHomePage ? IconsChat.Home : IconsChat.NavChat,
      iconActive: isHomePage ? IconsChat.NavHomeChat : IconsChat.NavActiveChat,
      path: isHomePage ? "/home-page" : "/chat",
      requireLogin: false,
    };

    setItems([mainItem, ...initItems]);
    setLoading(false);
  }, [pathName]);

  if (loading) {
    return (
      <div className="fixed inset-x-0 bottom-0 z-50 flex h-16 items-center border-t border-secondary-20 bg-white md:hidden">
        {Array.from({ length: 5 }).map((_) => (
          <div className="inline-flex shrink grow basis-0 cursor-pointer flex-col items-center justify-start">
            <Skeleton className="size-8" />
            <Skeleton className="mt-1 h-4 w-10" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 flex h-16 items-center border-t border-secondary-20 bg-white md:hidden">
      {items.map((item) => (
        <div
          key={`menu-item-${item.path}`}
          className="inline-flex shrink grow basis-0 cursor-pointer flex-col items-center justify-start"
          onClick={() => handleChangeNavigate(item.path, item.requireLogin)}
        >
          <div className="relative size-8">
            {isActive(item.path) ? (
              <item.iconActive className="relative size-[28px]" />
            ) : (
              <item.icon className="relative size-[28px]" />
            )}
            {item.path === "/local-chat" && totalMessageUnread > 0 && (
              <div className="absolute right-[1.5px] top-1 box-content size-2.5 rounded-full border border-white bg-[#F44622]"></div>
            )}
          </div>
          <div
            className={`self-stretch text-center text-xs font-medium ${
              isActive(item.path) ? "text-[#404867]" : "text-[#888DA0]"
            }`}
          >
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatNavigationBar;
