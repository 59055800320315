"use client";

import React from "react";
import useIsDesktop from "../../../../hooks/useIsDesktop";
import useMenuSideBarStore from "../../hooks/menu/use-menu-sidebar-store";

function SideBarWrapper({ children }) {
  const isDeskTop = useIsDesktop();
  const { isOpenSideBar, onCloseSideBar } = useMenuSideBarStore();

  if (isDeskTop) {
    return (
      <div className="sticky top-0 h-screen w-[200px]">
        <div className="flex size-full flex-col justify-between gap-8 overflow-y-auto border-r border-secondary-20 bg-[#F5F6FA] px-2 py-6">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 z-50 ">
      {/* Overlay */}
      {isOpenSideBar && (
        <div
          className="fixed inset-0 z-40 bg-black opacity-50"
          onClick={onCloseSideBar}
        />
      )}

      {/* Sidebar */}
      <div
        className={`relative z-50 flex h-[calc(100dvh-48px)] flex-col transition-all duration-200 ${
          isOpenSideBar ? "w-[85vw]" : "w-0"
        } z-50 justify-between overflow-hidden border-r border-secondary-20 bg-[#F5F6FA]`}
      >
        <div
          className={`flex h-full flex-col overflow-y-auto ${
            isOpenSideBar ? "gap-8 px-4 py-6 pb-20" : "hidden"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default SideBarWrapper;
