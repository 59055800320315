import { create } from "zustand";

interface MenuSideBarProps {
  isOpenSideBar: boolean;
  onOpenSideBar: () => void;
  onCloseSideBar: () => void;
}

const useMenuSideBarStore = create<MenuSideBarProps>((set) => ({
  isOpenSideBar: false,
  onOpenSideBar: () =>
    set((state) => ({ isOpenSideBar: !state.isOpenSideBar })),
  onCloseSideBar: () => set({ isOpenSideBar: false }),
}));

export default useMenuSideBarStore;
