"use client";

import { ChatbotMessageParser } from "@/actions/chatbot/type";
import {
  ConversationContext,
  defaultChatbotContext,
} from "@/lib/services/chatbot/type";
import { TravelBuddy } from "@prisma/client";
import { create } from "zustand";

export enum ChatSendType {
  USER = "USER",
  BOT = "BOT",
}

export interface QuestionProps {
  sender: ChatSendType.USER;
  text: string;
}
export interface QuestionProps2 extends ChatbotMessageParser {
  sender: ChatSendType.BOT;
  text: string;
  buddies: TravelBuddy[];
}
export interface ChatData {
  name: QuestionProps2 | QuestionProps;
}

interface ChatStore {
  chatId: string;
  textChat: string;
  country: string;
  loading: boolean;
  stream: boolean;
  chatData: ChatData["name"][];
  activitiesSavedIds: string[];
  selectedCity: number[];
  interests: string[];
  questions: string[];
  context: ConversationContext;
  eventSource: EventSource | null;
  setQuestions: (questions: string[]) => void;
  setEventSource: (eventSource: EventSource | null) => void;
  setStream: (stream: boolean) => void;
  setContext: (context: ConversationContext) => void;
  setActivitiesSavedIds: (ids: string[]) => void;
  setChatId: (text: string) => void;
  setTextChat: (text: string) => void;
  setLoading: (loading: boolean) => void;
  setInterests: (interests: string[]) => void;
  setSelectedCity: (
    newData: number[] | ((prevData: number[]) => number[])
  ) => void;
  setChatData: (
    chatData:
      | ChatData["name"][]
      | ((prevData: ChatData["name"][]) => ChatData["name"][])
  ) => void;
  reset: () => void;
}

export const initStore = {
  context: {
    ...defaultChatbotContext,
  },
  eventSource: null,
  stream: false,
  chatId: "",
  textChat: "",
  loading: false,
  interests: [],
  questions: [],
  chatData: [],
  selectedCity: [],
  activitiesSavedIds: [],
  country: "Vietnam",
};

const useChatStore = create<ChatStore>((set) => ({
  ...initStore,
  reset: () => {
    set((pre) => ({ ...initStore, stream: pre.stream }));
  },
  setEventSource: (eventSource) => set({ eventSource }),
  setQuestions: (questions) => set({ questions }),
  setStream: (stream) => set({ stream }),
  setContext: (context) => set({ context }),
  setChatId: (id) => set({ chatId: id }),
  setActivitiesSavedIds: (ids) => set({ activitiesSavedIds: ids }),
  setTextChat: (text) => set({ textChat: text }),
  setInterests: (state) => set({ interests: state }),
  setLoading: (state) => set({ loading: state }),
  setSelectedCity: (newData) =>
    set((prevState) => ({
      ...prevState,
      selectedCity:
        typeof newData === "function"
          ? (newData as (selectedCity: number[]) => number[])(
              prevState.selectedCity
            )
          : newData,
    })),
  setChatData: (newData) =>
    set((prevState) => ({
      ...prevState,
      chatData:
        typeof newData === "function"
          ? (newData as (chatData: ChatData["name"][]) => ChatData["name"][])(
              prevState.chatData
            )
          : newData,
    })),
}));

export default useChatStore;
