"use client";

import {
  getPlacesSaved,
  getConversations,
  mergeConversationAfterLogged,
} from "../../../../actions/chatbot";
import useLoginModalStore from "../../../../hooks/auth/use-login-modal-store";
import { getClientId } from "../../../../lib/local/laka-local-storage";
import {
  trackClickMyAccount,
  trackClickSignIn,
} from "../../../../lib/metrics/ga/homepage";
import useChatSocket from "../../hooks/messaging/use-chat-socket";
import useOpenWaitlistLocalGuide from "../../hooks/waitlist-localguide/use-wait-list-local-guide-store";
import useMessaging, {
  SESSION_RESET_STORAGE_KEY,
} from "../../states/messaging/use-messaging-store";
import useChatStore from "../../states/use-chat-store";
import SideBarContent from "./sidebar-content";
import SideBarWrapper from "./sidebar-wrapper";
import { getConversationsByUser } from "@/admin/actions/chat";
import { useSocket } from "@/components/socket-provider";
import { ChatbotConversation } from "@prisma/client";
import { useParams, usePathname, useRouter } from "next/navigation";
import { useSearchParams } from "next/navigation";
import { useQueryState } from "nuqs";
import { useEffect, useState } from "react";

interface ChatHistory {
  today: ChatbotConversation[];
  thisMonth: ChatbotConversation[];
  lastMonth: ChatbotConversation[];
}

const formatChatHistory = (data) => {
  const today = new Date();
  const chatHistory: any = {
    today: [],
    thisMonth: [],
    lastMonth: [],
  };
  data.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    // Check if the item is from today
    if (
      createdAt.toDateString() === today.toDateString() &&
      createdAt.getMonth() === today.getMonth() &&
      createdAt.getFullYear() === today.getFullYear()
    ) {
      chatHistory.today.push(item);
    }
    // Check if the item is from this month
    else if (
      createdAt.getMonth() === today.getMonth() &&
      createdAt.getFullYear() === today.getFullYear() &&
      createdAt.getDate() !== today.getDate()
    ) {
      chatHistory.thisMonth.push(item);
    }
    // Check if the item is from last month
    else {
      chatHistory.lastMonth.push(item);
    }
  });
  return chatHistory;
};

const ChatSideBar = ({ user }) => {
  const router = useRouter();
  const params = useParams();
  const searchParams = useSearchParams();
  const usePathName = usePathname();
  const { socket } = useSocket();
  const loginModal = useLoginModalStore();
  const [chatHistoryData, setChatHistoryData] = useState<ChatHistory>({
    today: [],
    thisMonth: [],
    lastMonth: [],
  });
  const [loginSuccess] = useQueryState("loginSuccess");
  const { messageData, setMessageData } = useMessaging();
  const { setActivitiesSavedIds } = useChatStore();
  const { onOpen } = useOpenWaitlistLocalGuide();

  useChatSocket(socket, setMessageData);

  useEffect(() => {
    if (loginSuccess) {
      onOpen(null, true);
      const params = new URLSearchParams(searchParams?.toString());
      params.delete("loginSuccess");
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, [loginSuccess]);

  useEffect(() => {
    const clientId = getClientId();

    mergeConversationAfterLogged(params?.chatId, clientId).then(() => {
      getConversations(clientId).then((res) => {
        if (res.success) {
          const formatData = formatChatHistory(res.data);
          setChatHistoryData(formatData);
        }
      });
      getPlacesSaved(clientId).then((res) => {
        if (res.success) {
          const newId = [
            ...res.data?.travelCities?.map((a) => String(a.id)),
            ...res.data?.travelActivities?.map((b) => String(b.id)),
          ];
          setActivitiesSavedIds(newId);
        }
      });
    });
  }, [params?.chatId, usePathName]);

  useEffect(() => {
    const resetKey = sessionStorage?.getItem(SESSION_RESET_STORAGE_KEY);
    console.log(resetKey, "resetKey");

    if (!resetKey && user) {
      getConversationsByUser().then((res) => {
        console.log(res, "res getConversationsByUser");

        if (res.success && !!res.data?.length) {
          const newMessageData =
            res.data?.map((chat) => {
              const buddyData = chat.chatMembers.find(
                (member) => member.user.TravelBuddy
              )?.user.TravelBuddy;
              return {
                ...chat,
                buddy: buddyData || null,
                badge: chat?.ConversationRead[0]?.countMessageUnread || 0,
                isFixed: false,
                messages: chat.messages,
                isGroup: !!chat.isGroup,
              };
            }) || [];

          setMessageData(newMessageData);
        }
      });
      sessionStorage?.setItem(SESSION_RESET_STORAGE_KEY, "true");
    }
  }, [user]);

  console.log(messageData, "messageData");

  const handleOpenLogin = () => {
    if (!user) {
      trackClickSignIn();
      loginModal.onOpen(`${window.location.href}`);
    } else {
      router.push("/personal");
      trackClickMyAccount();
    }
  };

  return (
    <SideBarWrapper>
      <SideBarContent
        chatHistory={chatHistoryData}
        user={user}
        handleOpenLogin={handleOpenLogin}
      />
    </SideBarWrapper>
  );
};

export default ChatSideBar;
