import { useParams, useRouter } from "next/navigation";
import useIsDesktop from "../../../../hooks/useIsDesktop";
import useMenuSideBarStore from "../../hooks/menu/use-menu-sidebar-store";

enum ChatSectionTitle {
  today = "Today",
  thisMonth = "This month",
  lastMonth = "Last Month",
}

const tempMessage =
  "Hi! I'm Trang from Laka. I connect you with the perfect local guide in Vietnam to create a personalized itinerary that matches your interests, budget, and travel style. 🌏";

const ChatHistorySection = ({ title, data }) => {
  const params = useParams();
  const router = useRouter();
  const { onCloseSideBar } = useMenuSideBarStore();
  const isDeskTop = useIsDesktop();
  const handleClickChatId = (id) => {
    router.push(`/chat/${id}`);
    if (!isDeskTop) {
      onCloseSideBar();
    }
  };

  return !!data.length ? (
    <div className="flex flex-col gap-1">
      <div className="text-xs font-medium text-secondary-60">
        {ChatSectionTitle[title]}
      </div>
      <>
        {data.map((item) => (
          <div
            onClick={() => handleClickChatId(item.id)}
            className={`cursor-pointer px-3 py-1.5 text-xs font-normal text-secondary-100 ${
              params?.chatId === item.id ? "rounded-full bg-white" : ""
            } truncate`}
            key={item.id}
          >
            {item.title || tempMessage}
          </div>
        ))}
      </>
    </div>
  ) : (
    <></>
  );
};

export default ChatHistorySection;
